<template>
  <NuxtLink
    v-bind="$attrs"
    ref="uiHtmlListItemElement"
    prefetch
    class="group flex flex-nowrap"
    :class="[
      {
        'p-0 text-white': Boolean(props.variant),
        'h-full flex-row flex-nowrap gap-x-3 motion-reduce:transition-none xl:flex-col xl:bg-white xl:shadow xl:transition-shadow xl:hover:shadow-lg':
          !Boolean(props.variant),
      },
      classGradient,
    ]"
    :to="props.slug"
  >
    <div
      class="shrink-0 overflow-hidden"
      :class="[
        props.sizeVariant === 'dense' ? 'aspect-news-in-list-dense' : 'aspect-news-in-list',
        {
          'w-6/12': Boolean(props.variant),
          'w-24 self-start xl:w-full': !Boolean(props.variant),
        },
      ]"
    >
      <UiImage
        v-if="parseImage(props.media?.[0]?.images?.defaultImage || props.media)"
        v-bind="
          parseImage(props.media?.[0]?.images?.defaultImage || props.media)
        "
        loading="lazy"
        width="424"
        height="285"
        class="size-full object-cover transition-transform duration-1000 ease-in-out group-hover:scale-110"
        fetchpriority="low"
      />
    </div>
    <component
      :is="newsItemTextComponent"
      class="flex flex-[2] flex-col justify-end xl:flex-auto"
      :class="[
        classGradient,
        {
          'w-6/12 py-10 pl-16 pr-10': Boolean(props.variant),
          'w-full xl:p-7': !Boolean(props.variant),
          'xl:!p-3.5 xl:!pb-5 xl:!pt-2.5': props.sizeVariant === 'dense',
        },
      ]"
      v-bind="props"
      :with-background="Boolean(props.variant)"
    >
      <HtmlParser
        v-if="props.teaser"
        :html="props.teaser"
        tag="p"
        :class="{
          'text-white [&>p]:text-white': Boolean(props.variant),
          '[&>p]:m-0': props.sizeVariant === 'dense',
        }"
        wysiwyg
      />
      <template
        v-for="(_, name) in $slots"
        #[name]="slotData"
      >
        <slot
          :name="name"
          v-bind="slotData"
        />
      </template>
    </component>
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import UiImage from '../../../UiImage/UiImage.vue'
import type { UiNewsItem, UiNewsListItemImage } from '../UiNewsListItem.types'

const props = withDefaults(defineProps<UiNewsItem>(), {
  teaser: '',
  datetime: '',
  datetimeShort: '',
  tags: () => [],
  categories: () => [],
  readTime: undefined,
  moreLink: '',
  variant: '',
  addMoreInfo: true,
  labels: null,
  colorOfGradient: '',
  sizeVariant: 'comfort',
})

// eslint-disable-next-line unused-imports/no-unused-vars
const UiNewsListItemText = defineAsyncComponent(() => {
  return import('../UiNewsListItemText/UiNewsListItemText.vue')
})
// eslint-disable-next-line unused-imports/no-unused-vars
const UiNewsListItemTextDense = defineAsyncComponent(() => {
  return import('../UiNewsListItemText/UiNewsListItemTextDense/UiNewsListItemTextDense.vue')
})

const uiHtmlListItemElement = ref<HTMLInputElement | null>(null)
defineExpose({
  uiHtmlListItemElement,
})

const classGradient = computed(() => {
  switch (props.colorOfGradient) {
    case 'gradient-blue':
      return 'bg-gradient-blue'
    case 'gradient-green':
      return 'bg-gradient-green'
    case 'gradient-bright-blue':
      return 'bg-gradient-bright-blue'
    case 'gradient-purple':
      return 'bg-gradient-purple'
    case 'gradient-blue-dark':
      return 'bg-gradient-dark-blue'
    default:
      return ''
  }
})

function parseImage(image: UiNewsListItemImage | string) {
  if (typeof image === 'string') {
    return {
      src: image,
    }
  }

  if (typeof image === 'object' && image !== null && !Array.isArray(image)) {
    if (Object.keys(image.cropVariants.listViewImage)?.length !== 0) {
      return {
        src: image.cropVariants?.listViewImage?.publicUrl || '',
      }
    }
    else {
      return {
        src: image?.publicUrl || '',
      }
    }
  }

  return null
}

const newsItemTextComponent = computed(() => {
  return props.sizeVariant === 'dense'
    ? resolveComponent('UiNewsListItemTextDense')
    : resolveComponent('UiNewsListItemText')
})
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
